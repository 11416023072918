.terminal-input {
	background-color: transparent;
	border-width: 0;
	flex-grow: 1;
	font-family: monospace;
	font-size: 13px;
	padding: 0;

	&:focus {
		outline: none;
	}
}