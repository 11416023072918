.arrow {
	color: #5efa68;
}

.location {
	color: #5ff9fa;
}

.git {
	color: #6670fc;
}

.branch {
	color: #f46963;
}

.ex {
	color: #fffb67;
	margin-right: 6px;
}