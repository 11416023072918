body {
	background-color: #000;
	font-family: monospace;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		color: #005bb9;
		text-decoration: underline;
	}
}

.App {
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 14px);
	padding: 4px 6px 10px;

	.row {
		display: flex;
	}

	.cursor {
		color: #c7c7c7;
	}

	.text {
		color: #b8b8b8;
	}
}

.ml-4 {
	margin-left: 16px;
}